<script lang="ts">
    import { t } from '$lib/i18n/i18n-store';
    import { isStringNotEmpty } from '@rigu/js-toolkit';

    let className: string;

    export { className as class };
    export let image = {
        alt: '',
        imagePath: '',
        hover: '',
        urlPath: '',
        bgClass: '',
        title: '',
    };

    let titleToShow = isStringNotEmpty(image.title) ? image.title : '';
</script>

<style lang="postcss">
    .hc-img-wrap {
        @apply block relative overflow-hidden cursor-pointer h-full;
    }

    .hc-img {
        @apply inset-0 w-full h-full object-cover object-center shadow-sm;
    }

    .hc-img-hover-bg, .hc-img-hover-txt {
        @apply invisible absolute inset-0 w-full h-full transition duration-700 ease-in-out;
    }

    .hc-img-hover-bg {
        @apply opacity-50;
    }

    .hc-img-hover-txt {
        @apply flex items-center justify-center fill-current antialiased px-3;
        @apply font-black text-center text-2xl text-transparent leading-tight;
    }

    .hc-img-wrap:hover .hc-img-hover-bg,
    .hc-img-wrap:hover .hc-img-hover-txt {
        @apply visible text-white;
    }

    .hc-title {
        /*text-shadow: 1px 1px 0 #727272, 1px -1px 0 #727272, -1px 1px 0 #727272, -1px -1px 0 #727272, 1px 0px 0 #727272, 0px 1px 0 #727272, -1px 0px 0 #727272, 0px -1px 0 #727272;*/
        @apply absolute px-1 py-0 text-xl;
    }
</style>

<a class="hc-img-wrap" href={image.urlPath}>
    <img class="hc-img" src={image.imagePath} alt="{$t(image.alt)}">
    <div class="hc-img-hover-bg bg-purple-900"></div>
    <div class="hc-img-hover-txt">
        {$t(image.hover)}
    </div>
    <div class="hc-title lg:hidden {className}">{$t(titleToShow).toLowerCase()}</div>
</a>
