<script lang="ts">
    import { t } from '$lib/i18n/i18n-store';

    export let card = {
        txt: '',
        signed: '',
        place: '',
        bgClass: '',
        roundClass: '',
    };
</script>

<style lang="postcss">
    .home-card-wrap {
        @apply shadow-sm mb-4;
    }

    .home-card {
        @apply border border-black overflow-hidden text-white;
    }

    .h-card-comma {
        font-size: 5rem;
        line-height: 0.5rem;
        @apply pl-5 uppercase tracking-tighter -mt-2 mb-2;
    }

    .h-card-main {
        min-height: 9rem;
        @apply px-5 flex items-center text-center;
    }

    .h-card-content {
        @apply w-full text-lg font-semibold leading-tight px-3;
    }

    .h-card-signed {
        @apply w-full text-xs mt-2;
    }

    .h-card-place {
        @apply w-full text-xs leading-3;
    }

    @media (min-width: 768px) {
        .h-rounded-tl {
            border-top-left-radius: 3rem;
        }

        .h-rounded-br {
            border-bottom-right-radius: 3rem;
        }
    }

</style>

<div class="{card.roundClass} home-card-wrap">
    <div class="{card.roundClass} home-card {card.bgClass} shadow-lg lg:shadow-xl">
        <div class="h-card-comma">,,</div>
        <div class="h-card-main">
            <div class="flex-1 w-full">
                <div class="h-card-content">{$t(card.txt)}</div>
                <div class="h-card-signed">{$t(card.signed)}</div>
                <div class="h-card-place">{$t(card.place)}</div>
            </div>
        </div>
    </div>
</div>
